import React from 'react';
import FlipCountdown from "@rumess/react-flip-countdown";
import bg from "./assets/images/heroimage2.png";

const CountDown = () => {
  // Create a Date object for August 2nd, 11:59 PM UK time (BST)
  const endDate = new Date(Date.UTC(2024, 7, 2, 22, 59, 0));

  return (
    <div className="bg-[#1F1D2A]">
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className="h-screen bg-cover flex justify-center items-center">
        <div className="font-bold text-white tracking-widest text-center" style={{ fontFamily: "'Red Hat Text', sans-serif" }}>
          <h1 className="lg:text-3xl md:text-2xl tracking-[10px] mb-20 text-[#F2F2F2]">WE'RE LAUNCHING SOON</h1>
          <div className="text-[#8486A9]">
            <FlipCountdown
              hideYear
              hideMonth
              theme='dark'
              size='large'
              titlePosition='bottom'
              endAt={endDate.toUTCString()}
              dayTitle='DAYS'
              hourTitle='HOURS'
              minuteTitle='MINUTES'
              secondTitle='SECONDS'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountDown;
