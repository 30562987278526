import image from "../../assets/images/evaluationbg2.png";
function formatMainName(name) {
  if (name === "weight_shape") {
    return name
      .replace(/_/g, "/") // Replace underscores with spaces
      .toLowerCase() // Convert to lowercase
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  } else {
    return name
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase() // Convert to lowercase
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  }
}

function generateEmailTemplate(formData, logo, graphUrl, email) {
  console.log("🚀 ~ generateEmailTemplate ~ formData:", formData)
  // Initialize variables for specific values
  let name,
    age,
    current_weight,
    goal_weight,
    commitment,
    gender,
    dietary,
    alcoholSpend,
    takeawaysSpend,
    energyBoostsSpend;
  // Extract specific values from formData
  formData.forEach((item) => {
    switch (item.mainName) {
      case "age":
        age =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "name":
        name =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "current_weight":
        current_weight =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "goal_weight":
        goal_weight =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "commitment":
        commitment =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "gender":
        gender =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "dietary":
        dietary =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "alcohol_spend":
        alcoholSpend =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "takeaways_spend":
        takeawaysSpend =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "energy_spend":
        energyBoostsSpend =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      default:
        break;
    }
  });

  const filteredItems = formData.filter(
    (item) =>
      ![
        "age",
        "goal",
        "commitment",
        "gender",
        "dietary",
        "energy_spend",
        "takeaways_spend",
        "alcohol_spend",
      ].includes(item.mainName)
  );

  // Split the filtered items into two arrays
  const lastSixItems = filteredItems.slice(-6); // Get the last 6 items
  const remainingItems = filteredItems.slice(1, -6); // Get all items except the last 6

  // Function to map and format each item
  const mapItemsToHtml = (items) =>
    items
      .map((item) => {
        const { mainName, answer, suggestions } = item;
        const formattedMainName = formatMainName(mainName);

        const answerString =
          typeof answer === "object" ? JSON.stringify(answer) : answer;
        const suggestionsString =
          typeof suggestions === "object"
            ? JSON.stringify(suggestions)
            : suggestions;

        return `
    <p>
      <span style="font-weight: bold;">${formattedMainName}</span>: ${
          formattedMainName === "Breakfast" || formattedMainName === "Water"
            ? `${answerString}/7`
            : answerString
        } ${suggestions ? ` - ${suggestions}` : ""}
    </p>
  `;
      })
      .join("");

  // Map the remaining items to HTML
  const wellnessItemsHtml = mapItemsToHtml(remainingItems);

  // Map the last 6 items to HTML
  const lastSixItemsHtml = mapItemsToHtml(lastSixItems);

  // Complete email template HTML
  let htmlContent = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Wellness Profile</title>
</head>
<body style=" background-image: url('../../assets/images/evaluationbg2.png'); background-size: cover; background-position: center; background-repeat: no-repeat; margin: 0; font-family: Arial, sans-serif; background-color: #535252; color: white; padding: 20px; max-width: 1400px; margin: 50px auto; border: 1px solid #C2BFBF; box-sizing: border-box;">

    <div style="display: grid; grid-template-columns: 2fr 1fr 1fr; grid-gap: 10px; padding: 5px;">
        <!-- Header Section -->
        <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px; grid-column: span 1; display: flex; justify-content: space-between; padding: 10px;">
            <div style="width: 100%; line-height: 1.5;">
                <img src="https://dev.mykilo.co.uk/static/media/Mykillo-New-Logo.20c123373bc6b3b11ef0.png" alt="Company Logo" style="max-width: 180px; height: auto;" />
                <p style="font-weight: bold;">Name: <span style="color: black; font-weight: normal;">${name}</span></p>
            </div>
        <div style="width: 60%; display: flex; flex-direction: column; align-items: flex-end;">
         
                <div style="margin-top: -20px;">
<h5 style="background-color:white; width: fit-content; color: black; padding: 10px 20px; border-radius: 10px; font-size: 16px; margin-bottom: 0px; font-weight: bold; border:1px solid #9C3022;">Online Wellness Profile</h5>
</div>
              
                <p>Age: ${age}</p>
            </div>
        </div>

        <!-- Weight Section -->
        <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
            <div style="background-color: white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 8px; font-weight: bold; border:1px solid #9C3022">WEIGHT</div>
            <p>Current: ${current_weight}</p>
            <p>Goal: ${goal_weight}</p>
        </div>

        <!-- Targets Section -->
        <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
            <div style="background-color: white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 8px; font-weight: bold; border:1px solid #9C3022">TARGETS</div>
            <p>Water: 2.3 litres/day</p>
            <p>Protein: 52.5 - 98g/day</p>
        </div>
    </div>

    <div style="display: grid; grid-template-columns: 2fr 1fr; grid-gap: 10px; padding: 5px;">
        <!-- Wellness Routine Section -->
        <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
            <div style="background-color:white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 8px; font-weight: bold; border:1px solid #9C3022">WELLNESS ROUTINE</div>
            <div>
                ${wellnessItemsHtml}
            </div>
        </div>

        <!-- Right Column for Spend, Dietary Requirements, and Other Information -->
        <div style="display: flex; flex-direction: column; gap: 10px;">
            <!-- Spend Section -->
            <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
                <div style="background-color:white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 8px; font-weight: bold; border:1px solid #9C3022 ">SPEND</div>
                <div style="display: flex; flex-direction: column; margin-top:20px;">
                    <div style="display: flex; justify-content: left; font-size: 16px; margin: 4px 0;">
                        <span>Takeaways: </span>
                            <span> ${
                              takeawaysSpend ? "£" + takeawaysSpend : ""
                            }</span>
                    </div>
                    <div style="display: flex; justify-content: left; font-size: 16px; margin: 4px 0;">
                        <span>Energy: </span>
                        <span> ${
                          energyBoostsSpend ? "£" + energyBoostsSpend : ""
                        }</span>
                    </div>

                    <div style="display: flex; justify-content: left; font-size: 16px; margin: 4px 0;">
                        <span>Alcohol: </span>
                       
                         <span> ${alcoholSpend ? "£" + alcoholSpend : ""}</span>
                    </div>
                 
               
                </div>
            </div>

            <!-- Dietary Requirements -->
            <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
                <div style="background-color:white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 14px; font-weight: bold; border:1px solid #9C3022">DIETARY REQUIREMENTS</div>
                <p> ${dietary ? dietary : "None"}</p>
            </div>

            <!-- Other Information -->
            <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
                <div style="background-color:white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 14px; font-weight: bold; border:1px solid #9C3022">OTHER INFORMATION</div>
                <!-- Add content as needed -->
                <p>None</p>
            </div>
        </div>


    </div>
<div style="background-color: white; padding: 15px; margin-top: 5px; border: 1px solid #C2BFBF; color: black; font-size: 16px; margin: 5px;">
            
            <div>
                ${lastSixItemsHtml}
            </div>
        </div>
</body>
</html>




  `;

  return htmlContent;
}

export default generateEmailTemplate;
