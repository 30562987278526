export const recipeFilterData = [
  {
    heading: "course",
    items: [
      {
        key: "Appetizers",
        text: "Appetizers",
      },
      {
        key: "Breakfast",
        text: "Breakfast",
      },
      {
        key: "Desserts",
        text: "Desserts",
      },
      {
        key: "Drinks",
        text: "Drinks",
      },
      {
        key: "Mains",
        text: "Mains",
      },
      {
        key: "Salads",
        text: "Salads",
      },
      {
        key: "Side_Dish",
        text: "Side Dish",
      },
      {
        key: "Snacks",
        text: "Snacks",
      },
    ],
  },
  {
    heading: "convenience",
    items: [
      {
        key: "3_Ingredients_Less",
        text: "3 Ingredients Less",
      },
      {
        key: "5_Ingredients_Less",
        text: "5 Ingredients Less",
      },
      {
        key: "Baking",
        text: "Baking",
      },
      {
        key: "Meal_Prep",
        text: "Meal Prep",
      },
      {
        key: "No_Cook",
        text: "No Cook",
      },
      {
        key: "One_Pan",
        text: "One Pan",
      },
    ],
  },
  {
    heading: "preference",
    items: [
      {
        key: "High_Protein",
        text: "High Protein",
      },
      {
        key: "Low_Carb",
        text: "Low Carb",
      },
      {
        key: "Low_Fat",
        text: "Low Fat",
      },
    ],
  },
  {
    heading: "dietary_restriction",
    allowMultiple: true,
    items: [
      {
        key: "Dairy_Free",
        text: "Dairy Free",
      },
      {
        key: "Gluten_Free",
        text: "Gluten Free",
      },
      {
        key: "Pescatarian",
        text: "Pescatarian",
      },
      {
        key: "Vegan",
        text: "Vegan",
      },
      {
        key: "Vegetarian",
        text: "Vegetarian",
      },
    ],
  },
  {
    heading: "duration",
    items: [
      {
        key: "30_Mins_Or_Less",
        text: "30 Mins Or less",
      },
      {
        key: "30_Mins_+",
        text: "30 Mins +",
      },
      {
        key: "10_Mins_Or_Less",
        text: "10 Mins Or Less",
      },
    ],
  },
];

export const workoutVideoFilterData = [
  {
    heading: "FOCUS_AREA",
    allowMultiple: true,
    items: [
      { key: "ABS", text: "Abs" },
      { key: "ARMS", text: "Arms" },
      { key: "BACK", text: "Back" },
      { key: "BOOTY", text: "Booty" },
      { key: "CHEST", text: "Chest" },
      { key: "FULL_BODY", text: "Full Body" },
      { key: "LEGS", text: "Legs" },
      { key: "LOWER_BODY", text: "Lower Body" },
      { key: "UPPER_BODY", text: "Upper Body" },
    ],
  },
  {
    heading: "WORKOUT_TYPE",
    allowMultiple: false,
    items: [
      { key: "BODY_WEIGHT_WORKOUTS", text: "Body Weight Workouts" },
      { key: "COOLDOWN", text: "Cooldown" },
      { key: "HIIT_CARDIO", text: "HIIT Cardio" },
      { key: "WEIGHTTED_WORKOUTS", text: "Weighted Workouts" },
      { key: "WARM_UP", text: "Warm Up" },
    ],
  },
  {
    heading: "PREFERENCE",
    allowMultiple: true,
    items: [
      { key: "LOW_IMPACT_ALTERNATIVES", text: "Low Impact Alternatives" },
      { key: "NO_BURPEES", text: "No Burpees" },
      { key: "NO_JUMPING", text: "No Jumping" },
      { key: "NO_PLANKS", text: "No Planks" },
      { key: "REPS_BASED", text: "Reps Based" },
      { key: "STANDING_WORKOUT", text: "Standing Workout" },
      { key: "WRIST_FRIENDLY", text: "Wrist Friendly" },
    ],
  },
  {
    heading: "DURATION",
    allowMultiple: false,
    items: [
      { key: "5_10_MIN", text: "5-10 Min" },
      { key: "10_15_MIN", text: "10-15 Min" },
      { key: "15_20_MIN", text: "15-20 Min" },
      { key: "20_MIN+", text: "20 Min+" },
    ],
  },
  {
    heading: "EQUIPMENT",
    allowMultiple: true,
    items: [
      { key: "BENCH", text: "Bench" },
      { key: "DUMBBELLS", text: "Dumbbells" },
      { key: "RESISTANCE_BANDS", text: "Resistance Bands" },
    ],
  },
  {
    heading: "HISTORY",
    allowMultiple: false,
    items: [
      { key: "I've_Completed", text: "I've Completed" },
      { key: "I've_Not_Completed", text: "I've Not Completed" },
    ],
  },
];

export const workoutProgramsFilterData = [
  {
    heading: "focus_type",
    allowMultiple: true,
    items: [
      { key: "ARMS", text: "Arms" },
      { key: "BOOTY", text: "Booty" },
      { key: "FULL_BODY", text: "Full Body" },
      { key: "LEGS", text: "Legs" },
      { key: "ABS&CORE", text: "Abs & Core" },
      { key: "RESISTANCE", text: "Resistance" },
      { key: "WEIGHT_LOSS", text: "Weight Loss" },
    ],
  },
  {
    heading: "program_categories",
    allowMultiple: false,
    items: [
      { key: "Latest_Challenges", text: "Latest Challenges" },
      { key: "Most_Popular", text: "Most Popular" },
      { key: "Beginner_Friendly", text: "Beginner Friendly" },
      { key: "Moderate_To_Advanced", text: "Moderate To Advanced" },
      { key: "Weight_Loss", text: "Weight Loss" },
      { key: "Abs", text: "Abs" },
      { key: "Booty_and_Legs", text: "Booty And Legs" },
      { key: "Strength_Training", text: "Strength Training" },
      { key: "No_Equipment", text: "No Equipment" },
    ],
  },
  {
    heading: "program_duration",
    allowMultiple: false,
    items: [
      { key: "1_14_DAYS", text: "1-14 Days" },
      { key: "15_21_DAYS", text: "15-21 Days" },
      { key: "22_28_DAYS", text: "22-28 Days" },
      { key: "29_DAYS+", text: "29 Days+" },
    ],
  },
  {
    heading: "program_year",
    allowMultiple: false,
    items: [
      { key: "2024", text: "2024" },
      { key: "2025", text: "2025" },
      { key: "2026", text: "2026" },
      { key: "2027", text: "2027" },
      { key: "2028", text: "2028" },
      { key: "2029", text: "2029" },
      { key: "2030", text: "2030" },
    ],
  },
  {
    heading: "equipment",
    allowMultiple: true,
    items: [
      { key: "DUMBBELLS", text: "Dumbbells" },
      { key: "FIRNESS_MAT", text: "Fitness Mat" },
      { key: "RESISTANCE_BANDS", text: "Resistance Bands" },
    ],
  },
  // {
  //   heading: "HISTORY",
  //   allowMultiple: false,
  //   items: [
  //     { key: "I've_Completed", text: "I've Completed" },
  //     { key: "I've_Not_Completed", text: "I've Not Completed" },
  //   ],
  // },
];
