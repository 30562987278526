// utils/healthEvaluationFormData.js
export const englishFormData = {
  steps: [
    {
      title: "Personal Details",
      fields: [
        {
          label: "What is your Name?*",
          type: "text",
          name: "name",
          error: "name is required",
          mainName: "name",
        },
        {
          label: "What is your age?*",
          type: "number",
          name: "age",
          error: "Age is required",
          mainName: "age",
        },
        {
          label: "What is your Gender?*",
          type: "select",
          name: "gender",
          options: ["Male", "Female", "Prefer not to say"],
          error: "Gender is required",
          mainName: "gender",
        },
      ],
    },
    {
      title: "Health Goals",
      fields: [
        {
          label: "What is your number one health goal?*",
          type: "text",
          name: "goal",
          error: "Goal is required",
          mainName: "goal",
        },
      ],
    },
    {
      title: "Commitment Level",
      fields: [
        {
          label:
            "How serious are you about working to achieve your health goal?*",
          type: "radio",
          name: "commitment",
          options: ["1", "2", "3", "4", "5"],
          error: "Please select any option",
          mainName: "commitment",
        },
      ],
    },
    {
      title: "Dietary Requirements",
      fields: [
        {
          label: "Please tick all that apply to your dietary requirements*",
          type: "checkbox",
          name: "dietary",
          error: "Please select any option",
          options: [
            "Vegan",
            "Vegetarian",
            "Gluten-Free",
            "Dairy-Free",
            "Keto",
            "None",
            "Other",
          ],
          mainName: "dietary",
        },
      ],
    },
    {
      title: "Daily Habits",
      fields: [
        {
          label:
            "On an average week, how many days out of 7 would you have a balanced healthy breakfast?*",
          type: "number",
          name: "daily",
          error: "Days are required",
          mainName: "breakfast",
          min: 0,
          max: 7,
        },
        {
          label:
            "On average, how many days out of 7 would you achieve drinking 8 glasses of water per day?*",
          type: "number",
          name: "habit",
          error: "Days are required",
          mainName: "water",
          min: 0,
          max: 7,
        },
       
      ],
    },
    {
      title: "Physical Activity",
      fields: [
        {
          label:
            "How frequently do you engage in physical exercise?*",
          type: "select",
          name: "exercises",

          options: [
            "Rarely or never",
            "Occasionally (1-2 times per week)",
            "Regularly (3+ times per week)"
          ],
          error: "Activity is required",
          mainName: "exercise",
          recommendations: [
            "Recommend starting with gentle activities like walking or swimming, gradually increasing intensity and frequency.",
            "Suggest incorporating strength training and flexibility exercises to complement their aerobic activity.",
            "Emphasis proper hydration, nutrition, and rest for recovery support.",
          ],
        },
        {
          label:
            "What type of physical activity do you enjoy or prefer?*",
          type: "select",
          name: "exercises_type",

          options: [
            "Group exercises (classes like Zumba or yoga)",
            "Solo workouts (running, gym, at-home)",
            "Low-impact exercises (yoga, Pilates)",
            "Strength training (weight lifting, resistance training)"
          ],
          error: "Activity is required",
          mainName: "exercises_type",
          recommendations: [
            "Recommend joining classes like Zumba, spinning, or yoga for motivation.",
            "Suggest using apps for guided workouts or setting personal goals to track progress.",
            "Recommend activities like Pilates, yoga, or elliptical machines to reduce joint strain.",
            "Suggest incorporating strength training and flexibility exercises to complement their aerobic activity."
          ],
        },
      ],
    },
    {
      title: "Nutrition Intake",
      fields: [
        {
          label:
            "What are your primary dietary goals or challenges?*",
          type: "select",
          name: "nutrition",
          error: "Nutrition is required",
          mainName: "nutrition",
          options: [
            "Struggling to eat healthily",
            "Aiming for weight loss",
            "Managing a health condition (diabetes)",
            "Managing a health condition (high cholesterol)",
            "Following a diet (vegan)",
            "Following a diet (gluten-free)"
          ],
          recommendations: [
            "Recommend planning meals in advance to ensure a balanced intake of nutrients, while avoiding processed foods.",
            "Emphasis portion control, reducing sugar intake, and increasing vegetables and lean protein.",
            "Suggest balancing carbohydrates with protein and fat, and monitoring blood glucose levels closely.",
            "Suggest reducing saturated fats and increasing fiber intake, including fruits, vegetables, and whole grains.",
            "Advise ensuring adequate protein from varied sources and considering supplements for B12, D, and omega-3 fatty acids.",
            "Recommend focusing on naturally gluten-free grains like quinoa and rice, and reading labels carefully."
          ],
        },
        {
          label:
            "How often do you dine out or rely on quick meal solutions?*",
          type: "select",
          name: "quick_meal",
          options: ["Frequently (3+ times a week)", "Occasionally (1-2 times a week)","Rarely or never"],
          recommendations:[
            "Recommend choosing healthier options, such as grilled items over fried foods, and asking for dressings on the side.",
            "Advise preparing healthy snacks and meals in bulk, and using quick-cooking whole grains like bulgur or couscous.",
            "Encourage experimenting with healthy recipes that substitute heavy creams or sugars with healthier alternatives.",

          ],
          error: "Please select any option",
          mainName: "quick_meal",
        },
        {
          label:
            "Do you have any dietary preferences or restrictions (e.g. vegan,gluten-free,organic)?*",
          type: "select",
          name: "dietary_options",
          options: ["Yes (vegan)","Yes (gluten-free)","Yes (organic)","No"],
          recommendations:[
            "Advise ensuring adequate protein from varied sources and considering supplements for B12, D, and omega-3 fatty acids.",
            "Recommend focusing on naturally gluten-free grains like quinoa and rice, and reading labels carefully.",
            "Suggest incorporating organic versions of frequently consumed foods, such as fruits, vegetables, and dairy.",
            ""
          ],
          error: "Please select any option",
          mainName: "dietary_options",
        },
      ],
    },   
    {
      title: "Energy Levels",
      fields: [
        {
          label:
            "Do you ever find yourself reaching for tea, coffee, energy drinks, or sugary foods for an energy boost during the day?*",
          type: "text",
          name: "energy",
          error: "Answer is required",
          mainName: "energy",
        },
      ],
    },
    {
      title: "Smoking and Vaping",
      fields: [
        {
          label: "Do you smoke or vape?*",
          type: "radio",
          name: "smoking",
          options: ["Yes", "No"],
          error: "Please select any option",
          mainName: "smoking",
        },
      ],
    },
    {
      title: "Protein Intake",
      fields: [
        {
          label:
            "In general, do you meet the daily target of 50-80g of lean protein?*",
          type: "radio",
          name: "protien",
          options: ["Yes", "No"],
          error: "Please select any option",
          mainName: "50-80g_protein",
        },
      ],
    },
    {
      title: "Body Weight and Shape",
      fields: [
        {
          label:
            "Do you struggle to reach and maintain a weight/shape that you are happy with?*",
          type: "radio",
          name: "body-weight",
          options: ["Yes", "No"],
          error: "Please select any option",
          mainName: "weight_shape",
        },
        {
          label: "What is your current weight?*",
          type: "select",
          name: "current_weight",
          options: [
            "40-50kg",
            "50-60kg",
            "60-70kg",
            "70-80kg",
            "80-90kg",
            "90-100kg",
            "100 kg & more",
          ],
          error: "Please select any option",
          mainName: "current_weight",
        },
        {
          label: "What is your ideal weight goal?*",
          type: "select",
          name: "goal_weight",
          options: [
            "40-50kg",
            "50-60kg",
            "60-70kg",
            "70-80kg",
            "80-90kg",
            "90-100kg",
            "100 kg & more",
          ],
          error: "Please select any option",
          mainName: "goal_weight",
        },
      ],
    },
    {
      title: "Struggles and Challenges",
      fields: [
        {
          label:
            "Do you struggle to reach and maintain a weight/shape that you are happy with?*",
          type: "checkbox",
          name: "struggles",
          error: "Please select any option",
          options: [
            "Hunger",
            "Lack of motivation",
            "Unsure where to start",
            "Uncertain what to eat",
            "Comfort eating",
            "Can't exercise",
            "Weekend disruptions",
          ],
          mainName: "struggles",
        },
      ],
    },
    {
      title: "Spends",
      fields: [
        {
          label: "On an average how much do you think you spend on alcohol?*",
          type: "number",
          min : 0,
          name: "alcohol_spend",
          error: "Alcohol spend is required",
          mainName: "alcohol_spend",
        },
        {
          label:
            "On an average week how much do you think you spend on takeaways, eating out or convenience meals (Meal deals, on the go snacks etc)?*",
          type: "number",
          min : 0,
          name: "takeaways_spend",
          error: "Alcohol spend is required",
          mainName: "takeaways_spend",
        },
        {
          label:
            "On an average week how much do you think you spend on energy boosts?(Energy drinks, takeaway coffees etc)?*",
          type: "number",
          min : 0,
          name: "energy_spend",
          error: "Alcohol spend is required",
          mainName: "energy_spend",
        },
      ],
    },
  ],
};

export const frenchFormData = {
  steps: [
    {
      title: "Personal Details",
      fields: [
        {
          label: "Nom?*",
          type: "text",
          name: "name",
          error: "name is required",
          mainName: "name",
        },
        {
          label: "sexe?*",
          type: "number",
          name: "age",
          error: "Age is required",
          mainName: "age",
        },
        {
          label: "Années?*",
          type: "select",
          name: "gender",
          options:["Je suis homme", "Je suis femme", "Je préfère ne pas répondre"],
          error: "Gender is required",
          mainName: "gender",
        },
      ],
    },
    {
      title: "Health Goals",
      fields: [
        {
          label: "Quel est votre objectif de santé?*",
          type: "text",
          name: "goal",
          error: "Goal is required",
          mainName: "goal",
        },
      ],
    },
    {
      title: "Commitment Level",
      fields: [
        {
          label:
            "Dans quelle mesure travaillez-vous sérieusement pour atteindre votre objectif de santé?*",
          type: "radio",
          name: "commitment",
          options: ["1", "2", "3", "4", "5"],
          error: "Please select any option",
          mainName: "commitment",
        },
      ],
    },
    {
      title: "Dietary Requirements",
      fields: [
        {
          label: "S’il vous plaît choisir toutes les cases qui s’appliquent à vos besoins alimentaires*",
          type: "checkbox",
          name: "dietary",
          error: "Please select any option",
          options: [
            "Vegan", "Végétarien", "Sans gluten", "Sans produits laitiers", "Keto", "Aucun", "Autre", "Tout sélectionner"
          ],
          mainName: "dietary",
        },
      ],
    },
    {
      title: "Daily Habits",
      fields: [
        {
          label:
            "Dans une semaine moyenne, combien de jours sur 7 avez-vous un petit déjeuner équilibré et sain?*",
          type: "number",
          name: "daily",
          error: "Days are required",
          mainName: "breakfast",
          min: 0,
          max: 7,
        },
        {
          label:
            "En moyenne, combien de jours sur 7 pouvez-vous boire 8 verres d’eau par jour?*",
          type: "number",
          name: "habit",
          error: "Days are required",
          mainName: "water",
          min: 0,
          max: 7,
        },
       
      ],
    },
    {
      title: "Physical Activity",
      fields: [
        {
          label:
            "Combien de fois vous exercez-vous?*",
          type: "select",
          name: "exercises",

          options:[
            "Rarement ou jamais",
            "Occasionnellement (1-2 fois par semaine)",
            "Régulièrement (3+ fois par semaine)"
          ],
          error: "Activity is required",
          mainName: "exercise",
          recommendations: [
            "Recommandez de commencer par des activités douces comme la marche ou la natation, en augmentant progressivement l'intensité et la fréquence.",
            "Il est suggéré d'intégrer des exercices de force et de flexibilité pour compléter l'activité aérobie.",
            "Emphasis proper hydration, nutrition, and rest for recovery support.",
          ],
        },
        {
          label:
            "Quel type d’activité physique aimez-vous ou préférez-vous pratiquer?*",
          type: "select",
          name: "exercises_type",

          options:[
            "Exercices de groupe (cours comme Zumba ou yoga)",
            "Entraînements en solo (course, gym, à domicile)",
            "Exercices à faible impact (yoga, Pilates)",
            "Entraînement de force (haltérophilie, entraînement de résistance)"
          ],
          error: "Activity is required",
          mainName: "exercises_type",
          recommendations: [
            "Il est recommandé de participer à des cours comme la Zumba, le spinning ou le yoga pour la motivation.",
            "Il est suggéré d'utiliser des applications pour des séances d'entraînement guidées ou de fixer des objectifs personnels pour suivre les progrès.",
            "Il est recommandé des activités comme le Pilates, le yoga ou les appareils elliptiques pour réduire la pression sur les articulations.",
            "Il est suggéré d'intégrer des exercices de force et de flexibilité pour compléter l'activité aérobie."
          ],
        },
      ],
    },
    {
      title: "Nutrition Intake",
      fields: [
        {
          label:
            "Quels sont vos principaux objectifs ou défis alimentaires?*",
          type: "select",
          name: "nutrition",
          error: "Nutrition is required",
          mainName: "nutrition",
          options:[
            "Difficulté à manger sainement",
            "Objectif de perte de poids",
            "Gérer une condition de santé (diabète)",
            "Gérer une condition de santé (cholestérol élevé)",
            "Suivre un régime (végétalien)",
            "Suivre un régime (sans gluten)"
          ],
          recommendations: [
            "Il est recommandé de planifier les repas à l'avance pour garantir un apport équilibré en nutriments et éviter les aliments transformés.",
            "Faites attention à vos portions, réduisez votre consommation de sucre et augmentez la consommation de légumes et de protéines maigres.",
            "Il est suggéré de balancer les glucides avec des protéines et des graisses, et de surveiller attentivement les niveaux de glucose dans le sang.",
            "Il est suggéré de réduire les graisses saturées et d'augmenter l'apport en fibres, y compris les fruits, les légumes et les céréales complètes.",
            "Il est conseillé de s'assurer d'un apport protéique adéquat provenant de différentes sources et de considérer la supplémentation en B12, D et acides gras oméga-3",
            "Il est recommandé de se concentrer sur des céréales naturellement sans gluten comme le quinoa et le riz, et de lire attentivement les étiquettes."
          ],
        },
        {
          label:
            "Combien de fois dînez-vous à l’extérieur ou à emporter ?*",
          type: "select",
          name: "quick_meal",
          options: [
            "Frequentemente (3+ volte a settimana)",
            "Occasionalmente (1-2 volte a settimana)",
            "Raramente o mai"
          ],
          recommendations:[
            "Il est recommandé de choisir des options plus saines, comme des aliments grillés plutôt que frits, et de demander les sauces et assaisonnements à part.",
            "Il est conseillé de préparer des collations et repas sains en grandes quantités et d'utiliser des céréales complètes à cuisson rapide comme le boulgour ou le couscous.",
            "Il est encouragé d'expérimenter des recettes saines qui substituent les crèmes ou sucres lourds par des alternatives plus saines.",

          ],
          error: "Please select any option",
          mainName: "quick_meal",
        },
        {
          label:
            "Avez-vous des préférences ou des restrictions alimentaires (par ex. végétalien, sans gluten, biologique)?*",
          type: "select",
          name: "dietary_options",
          options:[
            "Oui (végétalien)",
            "Oui (sans gluten)",
            "Oui (biologique)",
            "Non"
          ],
          recommendations:[
            "Il est conseillé de s'assurer d'un apport protéique adéquat provenant de différentes sources et de considérer la supplémentation en B12, D et acides gras oméga-3",
            "Il est recommandé de se concentrer sur des céréales naturellement sans gluten comme le quinoa et le riz, et de lire attentivement les étiquettes.",
            "Il est suggéré d'incorporer des versions biologiques d'aliments fréquemment consommés, comme les fruits, légumes et produits laitiers.",
            ""
          ],
          error: "Please select any option",
          mainName: "dietary_options",
        },
      ],
    },   
    {
      title: "Energy Levels",
      fields: [
        {
          label:
            "Avez-vous déjà essayé de boire du thé, du café, des boissons énergétiques ou des aliments sucrés pour augmenter votre énergie pendant la journée?*",
          type: "text",
          name: "energy",
          error: "Answer is required",
          mainName: "energy",
        },
      ],
    },
    {
      title: "Smoking and Vaping",
      fields: [
        {
          label: "Fumez ou vapez ?*",
          type: "radio",
          name: "smoking",
          options: ["Yes", "No"],
          error: "Please select any option",
          mainName: "smoking",
        },
      ],
    },
    {
      title: "Protein Intake",
      fields: [
        {
          label:
            "En général, pouvez-vous atteindre l’objectif quotidien de 50 à 80 g de protéines?*",
          type: "radio",
          name: "protien",
          options: ["Yes", "No"],
          error: "Please select any option",
          mainName: "50-80g_protein",
        },
      ],
    },
    {
      title: "Body Weight and Shape",
      fields: [
        {
          label:
            "Avez-vous des difficultés à atteindre et maintenir votre poids / forme idéal?*",
          type: "radio",
          name: "body-weight",
          options: ["Yes", "No"],
          error: "Please select any option",
          mainName: "weight_shape",
        },
        {
          label: "Quel est votre poids actuel?*",
          type: "select",
          name: "current_weight",
          options: [
            "40-50kg",
            "50-60kg",
            "60-70kg",
            "70-80kg",
            "80-90kg",
            "90-100kg",
            "100 kg & more",
          ],
          error: "Please select any option",
          mainName: "current_weight",
        },
        {
          label: "Quel est votre objectif de poids idéal?*",
          type: "select",
          name: "goal_weight",
          options: [
            "40-50kg",
            "50-60kg",
            "60-70kg",
            "70-80kg",
            "80-90kg",
            "90-100kg",
            "100 kg & more",
          ],
          error: "Please select any option",
          mainName: "goal_weight",
        },
      ],
    },
    {
      title: "Struggles and Challenges",
      fields: [
        {
          label:
            "Avez-vous des difficultés à atteindre et maintenir votre poids / forme idéal?*",
          type: "checkbox",
          name: "struggles",
          error: "Please select any option",
          options:[
            "Faim",
            "Manque de motivation",
            "Incertitude sur par où commencer",
            "Incertitude sur quoi manger",
            "Manger par réconfort",
            "Ne peut pas faire d'exercice",
            "Perturbations le week-end"
          ],
          mainName: "struggles",
        },
      ],
    },
    {
      title: "Spends",
      fields: [
        {
          label: "Combien dépensez-vous en moyenne pour l’alcool ?*",
          type: "number",
          min : 0,
          name: "alcohol_spend",
          error: "Alcohol spend is required",
          mainName: "alcohol_spend",
        },
        {
          label:
            "En moyenne, combien pensez-vous dépenser par semaine pour les plats à emporter, les repas au restaurant ou les repas pratiques (offres repas, en-cas à emporter, etc.) ?*",
          type: "number",
          min : 0,
          name: "takeaways_spend",
          error: "Alcohol spend is required",
          mainName: "takeaways_spend",
        },
        {
          label:
            "En moyenne, combien pensez-vous dépenser sur les augmentations d’énergie ?(boissons énergétiques, café à emporter, etc.) ?*",
          type: "number",
          min : 0,
          name: "energy_spend",
          error: "Alcohol spend is required",
          mainName: "energy_spend",
        },
      ],
    },
  ],
};
export const italianFormData = {
  steps: [
    {
      title: "Personal Details",
      fields: [
        {
          label: "Nome?*",
          type: "text",
          name: "name",
          error: "name is required",
          mainName: "name",
        },
        {
          label: "sesso?*",
          type: "number",
          name: "age",
          error: "Age is required",
          mainName: "age",
        },
        {
          label: "Anni?*",
          type: "select",
          name: "gender",
          options: ["Sono maschio", "Sono femmina", "Preferisco non rispondere"],
          error: "Gender is required",
          mainName: "gender",
        },
      ],
    },
    {
      title: "Health Goals",
      fields: [
        {
          label: "Qual è il tuo principale obiettivo di salute?*",
          type: "text",
          name: "goal",
          error: "Goal is required",
          mainName: "goal",
        },
      ],
    },
    {
      title: "Commitment Level",
      fields: [
        {
          label:
            "Quanto seriamente stai lavorando per raggiungere il tuo obiettivo di salute?*",
          type: "radio",
          name: "commitment",
          options: ["1", "2", "3", "4", "5"],
          error: "Please select any option",
          mainName: "commitment",
        },
      ],
    },
    {
      title: "Dietary Requirements",
      fields: [
        {
          label: "Si prega di scegliere tutte le caselle che si applicano alle vostre esigenze alimentari*",
          type: "checkbox",
          name: "dietary",
          error: "Please select any option",
          options: [
           "Vegan", "Vegetariano", "Senza glutine", "Senza latticini", "Keto", "Nessuno", "Altro", "Seleziona tutto"
          ],
          mainName: "dietary",
        },
      ],
    },
    {
      title: "Daily Habits",
      fields: [
        {
          label:
            "In una settimana media, quanti giorni su 7 avresti una colazione sana equilibrata?*",
          type: "number",
          name: "daily",
          error: "Days are required",
          mainName: "breakfast",
          min: 0,
          max: 7,
        },
        {
          label:
            "In media, quanti giorni su 7 si riesci a bere 8 bicchieri d'acqua al giorno?*",
          type: "number",
          name: "habit",
          error: "Days are required",
          mainName: "water",
          min: 0,
          max: 7,
        },
       
      ],
    },
    {
      title: "Physical Activity",
      fields: [
        {
          label:
            "Quanto spesso fai esercizio fisico?*",
          type: "select",
          name: "exercises",

          options: [
            "Raramente o mai",
            "Occasionalmente (1-2 volte a settimana)",
            "Regolarmente (3+ volte a settimana)"
          ],
          error: "Activity is required",
          mainName: "exercise",
          recommendations: [
            "Consigliate di iniziare con attività leggere come camminare o nuotare, aumentando gradualmente l'intensità e la frequenza.",
            "Si suggerisce di integrare esercizi di forza e flessibilità per completare l'attività aerobica.",
            "Emphasis proper hydration, nutrition, and rest for recovery support.",
          ],
        },
        {
          label:
            "Che tipo di attività fisica ti piace o preferisci svolgere?*",
          type: "select",
          name: "exercises_type",

          options:[
            "Esercizi di gruppo (lezioni come Zumba o yoga)",
            "Allenamenti individuali (corsa, palestra, a casa)",
            "Esercizi a basso impatto (yoga, Pilates)",
            "Allenamento di forza (sollevamento pesi, allenamento con resistenza)"
          ],
          error: "Activity is required",
          mainName: "exercises_type",
          recommendations: [
            "Si Raccomanda di partecipare a lezioni come Zumba, spinning o yoga per motivazione.",
            "Si Suggerisce di utilizzare app per allenamenti guidati o di impostare obiettivi personali per monitorare i progressi.",
            "Si raccomandano attività come Pilates, yoga o macchine ellittiche per ridurre la pressione sulle articolazioni.",
            "Si suggerisce di integrare esercizi di forza e flessibilità per completare l'attività aerobica."
          ],
        },
      ],
    },
    {
      title: "Nutrition Intake",
      fields: [
        {
          label:
            "Quali sono i vostri obiettivi o le sfide alimentari principali?*",
          type: "select",
          name: "nutrition",
          error: "Nutrition is required",
          mainName: "nutrition",
          options:[
            "Difficoltà a mangiare in modo sano",
            "Obiettivo di perdere peso",
            "Gestire una condizione di salute (diabete)",
            "Gestire una condizione di salute (colesterolo alto)",
            "Seguire una dieta (vegana)",
            "Seguire una dieta (senza glutine)"
          ]
          ,
          recommendations: [
            "Si consiglia di pianificare i pasti in anticipo per garantire un apporto equilibrato di nutrienti ed evitare alimenti transformati",
            "Presta attenzione alle tue porzioni, la riduzione dell'assunzione di zuccheri e l'aumento del consumo di verdure e proteine magre.",
            "Si suggerisce di bilanciare carboidrati con proteine e grassi e di monitorare attentamente i livelli di glucosio nel sangue.",
            "Si suggerisce di ridurre i grassi saturi e aumentare l'assunzione di fibre, inclusi frutta, verdura e cereali integrali.",
            "Si consiglia di assicurare un adeguato apporto proteico da fonti diverse e di considerare l'integrazione di B12, D e acidi grassi omega-3",
            "Si raccomanda di concentrarsi su cereali naturalmente privi di glutine come quinoa e riso, e leggere attentamente le etichette."
          ],
        },
        {
          label:
            "Quanto spesso ceni fuori o cibo da asporto?*",
          type: "select",
          name: "quick_meal",
          options:[
            "Frequentemente (3+ volte a settimana)",
            "Occasionalmente (1-2 volte a settimana)",
            "Raramente o mai"
          ],
          recommendations:[
            "Si raccomanda di scegliere opzioni più salutari, come cibi grigliati al posto di quelli fritti, e di richiedere salse e condimenti a parte.",
            "Si consiglia di preparare snack e pasti salutari in grandi quantità e di usare cereali integrali a cottura rapida come bulgur o cous cous.",
            "Si incoraggia a sperimentare ricette salutari che sostituiscano creme o zuccheri pesanti con alternative più sane.",

          ],
          error: "Please select any option",
          mainName: "quick_meal",
        },
        {
          label:
            "Hai preferenze o restrizioni alimentari (ad es. vegano, senza glutine, biologico)?*",
          type: "select",
          name: "dietary_options",
          options: [
            "Sì (vegano)",
            "Sì (senza glutine)",
            "Sì (biologico)",
            "No"
          ],
          recommendations:[
            "Si consiglia di assicurare un adeguato apporto proteico da fonti diverse e di considerare l'integrazione di B12, D e acidi grassi omega-3",
            "Si raccomanda di concentrarsi su cereali naturalmente privi di glutine come quinoa e riso, e leggere attentamente le etichette.",
            "Si suggerisce di incorporare versioni biologiche di alimenti consumati frequentemente, come frutta, verdura e latticini.",
            ""
          ],
          error: "Please select any option",
          mainName: "dietary_options",
        },
      ],
    },   
    {
      title: "Energy Levels",
      fields: [
        {
          label:
            "Ti capita mai di cercare tè, caffè, bevande energetiche o cibi zuccherati per aumentare la tua energia durante il giorno?*",
          type: "text",
          name: "energy",
          error: "Answer is required",
          mainName: "energy",
        },
      ],
    },
    {
      title: "Smoking and Vaping",
      fields: [
        {
          label: "Fumi o svapi?*",
          type: "radio",
          name: "smoking",
          options: ["Yes", "No"],
          error: "Please select any option",
          mainName: "smoking",
        },
      ],
    },
    {
      title: "Protein Intake",
      fields: [
        {
          label:
            "In generale, riesci a raggiunge l'obiettivo giornaliero di 50-80g di proteine?*",
          type: "radio",
          name: "protien",
          options: ["Yes", "No"],
          error: "Please select any option",
          mainName: "50-80g_protein",
        },
      ],
    },
    {
      title: "Body Weight and Shape",
      fields: [
        {
          label:
            "Hai difficoltà a raggiungere e mantenere il tuo peso/ forma ideale?*",
          type: "radio",
          name: "body-weight",
          options: ["Yes", "No"],
          error: "Please select any option",
          mainName: "weight_shape",
        },
        {
          label: "Qual è il tuo peso attuale?*",
          type: "select",
          name: "current_weight",
          options: [
            "40-50kg",
            "50-60kg",
            "60-70kg",
            "70-80kg",
            "80-90kg",
            "90-100kg",
            "100 kg & more",
          ],
          error: "Please select any option",
          mainName: "current_weight",
        },
        {
          label: "Qual è il tuo obiettivo di peso ideale?*",
          type: "select",
          name: "goal_weight",
          options: [
            "40-50kg",
            "50-60kg",
            "60-70kg",
            "70-80kg",
            "80-90kg",
            "90-100kg",
            "100 kg & more",
          ],
          error: "Please select any option",
          mainName: "goal_weight",
        },
      ],
    },
    {
      title: "Struggles and Challenges",
      fields: [
        {
          label:
            "Hai difficoltà a raggiungere e mantenere il tuo peso/ forma ideale?*",
          type: "checkbox",
          name: "struggles",
          error: "Please select any option",
          options:[
            "Fame",
            "Mancanza di motivazione",
            "Non sapere da dove iniziare",
            "Incertezza su cosa mangiare",
            "Mangiare per conforto",
            "Non posso fare esercizio",
            "Interruzioni nel weekend"
          ] ,
          mainName: "struggles",
        },
      ],
    },
    {
      title: "Spends",
      fields: [
        {
          label: "In media quanto spendi per l'alcol?*",
          type: "number",
          min : 0,
          name: "alcohol_spend",
          error: "Alcohol spend is required",
          mainName: "alcohol_spend",
        },
        {
          label:
            "In una settimana media quanto pensi di spendere su cibo d'asporto, mangiare fuori o pasti convenienti?*",
          type: "number",
          min : 0,
          name: "takeaways_spend",
          error: "Alcohol spend is required",
          mainName: "takeaways_spend",
        },
        {
          label:
            " In una settimana media quanto pensi di spendere su gli aumenti di energia?(bevande energetiche, caffè da asporto ecc.)?*",
          type: "number",
          min : 0,
          name: "energy_spend",
          error: "Alcohol spend is required",
          mainName: "energy_spend",
        },
      ],
    },
  ],
};